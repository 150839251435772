<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/cooperation-partner' }">
        <app-i18n code="entities.cooperationPartner.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.cooperationPartner.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.cooperationPartner.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-cooperation-partner-view-toolbar
        v-if="record"
      ></app-cooperation-partner-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.lastName.label"
          :value="presenter(record, 'lastName')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.firstName.label"
          :value="presenter(record, 'firstName')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.title.label"
          :value="presenter(record, 'title')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.emailAddress.label"
          :value="presenter(record, 'emailAddress')"
        ></app-view-item-text>
               
        <app-view-item-text
          :label="fields.institutionName.label"
          :value="presenter(record, 'institutionName')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.address.label"
          :value="presenter(record, 'address')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.contactPerson.label"
          :value="presenter(record, 'contactPerson')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.webAddress.label"
          :value="presenter(record, 'webAddress')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.partner.label"
          :value="presenter(record, 'partner')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CooperationPartnerViewToolbar from '@/modules/cooperation-partner/components/cooperation-partner-view-toolbar.vue';
import { CooperationPartnerModel } from '@/modules/cooperation-partner/cooperation-partner-model';

const { fields } = CooperationPartnerModel;

export default {
  name: 'app-cooperation-partner-view-page',

  props: ['id'],

  components: {
    [CooperationPartnerViewToolbar.name]: CooperationPartnerViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'cooperationPartner/view/record',
      loading: 'cooperationPartner/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'cooperationPartner/view/doFind',
    }),

    presenter(record, fieldName) {
      return CooperationPartnerModel.presenter(record, fieldName);
    },
  },
};
</script>
